import React, { useEffect, useRef, useState } from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';
import { WS_SSH } from "../../Globales/MetodosAPIs";
import FolderIcon from '@mui/icons-material/Folder';
import RefreshIcon from '@mui/icons-material/Refresh';
import CodeIcon from '@mui/icons-material/Code';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Paper,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Alert,
  Tooltip,
  LinearProgress,
  IconButton, Menu, MenuItem, CircularProgress
} from '@mui/material';
import './styles/styles.css';
import {KeyboardCommandKey} from "@mui/icons-material";

const SshTerminal = ({ token, session = 1 }) => {
  const terminalRef = useRef(null);
  const socketRef = useRef(null);
  const term = useRef(null);
  const fitAddon = useRef(new FitAddon());
  const inputBuffer = useRef('');
  const [prePrompt, setPrePrompt] = useState("\x1b[33msimulink@intelisysdev: \x1b[0m");
  const [openDialog, setOpenDialog] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [filePath, setFilePath] = useState('');
  const [isFileSaved, setIsFileSaved] = useState(true);
  const [transferProgress, setTransferProgress] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const originalContent = useRef('');
  const archivosYDirectoriosDisponiblesRef = useRef([]);
  const archivosUsuarioRef = useRef([]);
  const commandHistory = useRef([]);
  const historyIndex = useRef(-1);
  const [anchorEl, setAnchorEl] = useState({});
  const sessionFilesIconRef = useRef(<FolderIcon />);
  const transferTimeoutRef = useRef(null);
  const [_, setForceUpdate] = useState(0); // Esto es solo para forzar una actualización de estado
  const fileChunksRef = useRef({});

  const forceUpdate = () => {
    setForceUpdate(prev => prev + 1);
  };
  const handleFileDownload = (fileId) => {
      const message = {
        action: 'request_file_download',
        values: {
          id: fileId,
        }
      };
      socketRef.current.send(JSON.stringify(message));
    };


  const resetSessionFilesIcon = () => {
    sessionFilesIconRef.current = <FolderIcon />;
    forceUpdate();
    if (transferTimeoutRef.current) {
      clearTimeout(transferTimeoutRef.current);
    }
  };



  const getFileIcon = (fileName) => {
    if (!fileName) return <InsertDriveFileIcon />;

    if (fileName.endsWith('.py') || fileName.endsWith('.js')) {
      return <CodeIcon />;
    } else if (fileName.endsWith('.tar') || fileName.endsWith('.gz')) {
      return <FolderZipIcon />;
    } else {
      return <InsertDriveFileIcon />;
    }
  };




  const formatMessage = (message) => {
    const regex = /\[%([^%]+)%\]\[#([a-fA-F0-9]{6})#\]/g;
    let formattedMessage = '';
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(message)) !== null) {
      const text = match[1];
      const color = match[2];
      const ansiColor = `\x1b[38;2;${parseInt(color.substring(0, 2), 16)};${parseInt(color.substring(2, 4), 16)};${parseInt(color.substring(4, 6), 16)}m`;
      formattedMessage += message.substring(lastIndex, match.index);
      formattedMessage += `${ansiColor}${text}\x1b[0m`;
      lastIndex = regex.lastIndex;
    }

    formattedMessage += message.substring(lastIndex);
    return formattedMessage;
  };

  useEffect(() => {
    if (term.current === null) {
      term.current = new Terminal({
        rows: 40
      });
      term.current.loadAddon(fitAddon.current);
      term.current.open(terminalRef.current);
      fitAddon.current.fit();

      let lastCharTime = 0;
      const debounceTime = 50;
      let lock = false;

      term.current.onData(e => {
        const currentTime = Date.now();
        if (currentTime - lastCharTime < debounceTime) {
          return;
        }

        if (lock) {
          return;
        }

        lock = true;
        lastCharTime = currentTime;

        const char = e;
        if (char === '\r') {
          const input = inputBuffer.current.trim();
          if (input) {
            if (commandHistory.current.length === 0 || commandHistory.current[commandHistory.current.length - 1] !== input) {
              commandHistory.current = [...commandHistory.current, input];
            }
            historyIndex.current = -1;
          }
          term.current.write('\r\n');
          handleInput(input);
          inputBuffer.current = '';
        } else if (char === '\u007F') {
          if (inputBuffer.current.length > 0) {
            inputBuffer.current = inputBuffer.current.slice(0, -1);
            term.current.write('\b \b');
          }
        } else if (char === '\u001b[A') {
          if (historyIndex.current < commandHistory.current.length - 1) {
            historyIndex.current += 1;
            const command = commandHistory.current[commandHistory.current.length - 1 - historyIndex.current];
            term.current.write('\r\x1b[K' + prePrompt + "$ " + command);
            inputBuffer.current = command;
          }
        } else if (char === '\u001b[B') {
          if (historyIndex.current > 0) {
            historyIndex.current -= 1;
            const command = commandHistory.current[commandHistory.current.length - 1 - historyIndex.current];
            term.current.write('\r\x1b[K' + prePrompt + "$ " + command);
            inputBuffer.current = command;
          } else if (historyIndex.current === 0) {
            historyIndex.current -= 1;
            term.current.write('\r\x1b[K' + prePrompt + "$ ");
            inputBuffer.current = '';
          }
        }  else if (char === '\u0009') { // Tab key
            const inputParts = inputBuffer.current.split(' ');
            const lastPart = inputParts.pop(); // Remove the last part
            const prefix = inputParts.join(' ') + (inputParts.length > 0 ? ' ' : '');


            const match = archivosYDirectoriosDisponiblesRef.current.filter(item => {
              return item.name.startsWith(lastPart);
            });

            if (match.length === 1) {
              const completion = match[0].name.substring(lastPart.length);
              term.current.write(completion);
              inputBuffer.current = prefix + match[0].name;
            } else if (match.length > 1) {
              const commonPrefix = match.reduce((prefix, item) => {
                let i = 0;
                while (i < prefix.length && prefix[i] === item.name[i]) {
                  i++;
                }
                return prefix.substring(0, i);
              }, match[0].name);
              const completion = commonPrefix.substring(lastPart.length);
              term.current.write(completion);
              inputBuffer.current = prefix + commonPrefix;
            }
          } else {
            inputBuffer.current += char;
            term.current.write(char);
          }

        lock = false;
      });

      term.current.write(prePrompt + "$ ");
    }



    const connectWebSocket = () => {
      const wsUrl = WS_SSH.replace('<token>', token);
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        term.current.writeln('Conectando al Servidor SSH, espere ...');
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Recibimos: ", data);

        if (data.action === 'text_file') {
          setFilePath(data.values.path);
          setFileContent(data.values.content);
          originalContent.current = data.values.content;
          setIsFileSaved(true);
          setOpenDialog(true);
        } else if (data.action === 'file_saved') {
          setIsFileSaved(true);
        } else if (data.action === "file_transfer_progress") {
           setTransferProgress(data.values.progress);
            sessionFilesIconRef.current = (
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={data.values.progress} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" component="div" color="white">
                    {`${data.values.progress}%`}
                  </Typography>
                </Box>
              </Box>
            );
            forceUpdate();
            if (transferTimeoutRef.current) {
              clearTimeout(transferTimeoutRef.current);
            }
            transferTimeoutRef.current = setTimeout(resetSessionFilesIcon, 5000);

        } else if (data.action === 'file_transfer_success') {
            setShowSuccessAlert(true);
            setTransferProgress(null);
            resetSessionFilesIcon();
            setTimeout(() => setShowSuccessAlert(false), 5000);
        } else if (data.action === 'actual_files_and_dirs') {
            archivosYDirectoriosDisponiblesRef.current = data.values.list;
        }else if (data.action === 'user_files_list') {
          archivosUsuarioRef.current = data.values.files;
        } else if (data.action === 'downloading_file') {
            const { file_name, actual_chunk, chunk_content, total_chunks } = data.values;

            // Decode base64 chunk content
            const decodedChunkContent = atob(chunk_content);

            // Save the chunk in localStorage or a variable
            if (!fileChunksRef.current[file_name]) {
                fileChunksRef.current[file_name] = [];
            }

            if (actual_chunk <= total_chunks) {
                fileChunksRef.current[file_name][actual_chunk - 1] = decodedChunkContent;
            }

            // If it's the last chunk, combine chunks and download the file
            if (actual_chunk === total_chunks) {
                console.log("Se descarga porque el actual_chunk es == total");
                console.log("Actual: ", actual_chunk, "  Total: ", total_chunks);
                const fileChunks = fileChunksRef.current[file_name];
                const fileContent = fileChunks.join('');
                const byteCharacters = fileContent.split('').map(char => char.charCodeAt(0));
                const byteArray = new Uint8Array(byteCharacters);
                const blob = new Blob([byteArray], { type: 'application/octet-stream' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = file_name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Reset the session file icon and show success alert
                resetSessionFilesIcon();
                setShowSuccessAlert(true);
                setTransferProgress(null);
                setTimeout(() => setShowSuccessAlert(false), 5000);

                // Clear the file chunks
                delete fileChunksRef.current[file_name];
            } else {
                // Request the next chunk
                const message = {
                    action: 'request_next_downloading_chunk',
                    values: {
                        file_name: file_name,
                        next_chunk: actual_chunk + 1,
                        total_chunks: total_chunks
                    }
                };
                socketRef.current.send(JSON.stringify(message));
            }
        } else if (data.action === 'file_downloaded') {
          resetSessionFilesIcon();
          setShowSuccessAlert(true);
          setTransferProgress(null);
          setTimeout(() => setShowSuccessAlert(false), 5000);
        }

        else {
          const message = data.message.values.message || "Comando Invalido";
          const formattedMessage = formatMessage(message);
          const newPrompt = `\x1b[33m${data.message.values.exec_path} \x1b[0m`;

          if (inputBuffer.current.length === 0) {
            term.current.write('\r\x1b[K');
          }

          term.current.writeln(formattedMessage);
          inputBuffer.current = '';

          setPrePrompt(newPrompt);
          term.current.write(newPrompt + "$ ");
        }
      };

      ws.onclose = () => {
        term.current.writeln('Se perdio la conexion con el servidor SSH. Salga y vuelva a ingresar');
      };

      socketRef.current = ws;
    };

    const timeoutId = setTimeout(connectWebSocket, 1000);

    return () => {
      clearTimeout(timeoutId);
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [token]);

  const handleListUserFiles = () => {
    const message = {
      action: 'list_user_files',
      values: {
      }
    };
    socketRef.current.send(JSON.stringify(message));
  };


  const handleInput = (input) => {
    if (input.trim() === 'clear') {
      term.current.clear();
      term.current.write(prePrompt + "$ ");
      return;
    }

    if (socketRef.current) {
      const cleanedInput = input.replace(/\$ $/, '').trim();
      const message = JSON.stringify({ action: 'command', values: { message: cleanedInput } });
      socketRef.current.send(message);
    }

    term.current.write(prePrompt + "$ ");
  };

  const handleFileChange = (e) => {
    setFileContent(e.target.value);
    if (e.target.value !== originalContent.current) {
      setIsFileSaved(false);
    } else {
      setIsFileSaved(true);
    }
  };

  const handleSaveFile = () => {
    const message = {
      action: 'text_file_change',
      values: {
        path: filePath,
        content: fileContent
      }
    };
    socketRef.current.send(JSON.stringify(message));
  };

  const handleDialogClose = () => {
    const message = JSON.stringify({ action: 'command', values: { message: '\u0018' } });
    socketRef.current.send(message);
    setOpenDialog(false);
  };

  const sendSpecialCommand = (command) => {
    let specialCommand = '';
    switch (command) {
      case 'Ctrl+C':
        specialCommand = '\u0003';
        break;
    }

    if (specialCommand) {
      if (socketRef.current) {
        const message = JSON.stringify({ action: 'command', values: { message: specialCommand } });
        socketRef.current.send(message);
      }

      if (command === 'Ctrl+C') {
        term.current.write('\r\n');
      }

      term.current.write(prePrompt);
    }
  };



  const handleClick = (event, menu) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [menu]: prevState[menu] ? null : event.currentTarget,
    }));

    if (!anchorEl[menu]) {
      handleListUserFiles();
    }
  };


  const handleClose = (menu) => {
    setAnchorEl(prevState => ({
      ...prevState,
      [menu]: null,
    }));
  };


  useEffect(() => {
    const handleResize = () => {
      if (term.current) {
        fitAddon.current.fit();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

   return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      sx={{
        paddingLeft: { xs: '10px', md: '100px', lg: '100px', xl: '100px', xxl: '100px' },
        paddingRight: { xs: '10px', md: '250px', lg: '250px', xl: '250px', xxl: '250px' },
        pt:1,
        pb:1
      }}
    >
      <Paper
        elevation={5}
        style={{
          width: 'calc(100% - 500px)',  // Ajuste del ancho para respetar los márgenes
          height: '100%',
          backgroundColor: '#000',  // Cambia el color de fondo a gris oscuro
          borderRadius: '10px',
          overflow: 'hidden',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <Typography
          variant="h6"
          style={{ color: 'white', textAlign: 'center', marginBottom: '10px' }}
        >
          SSH Terminal WS
        </Typography>
        <Paper
          elevation={5}
          style={{
            width: 'calc(100% - 20px)',  // Ajuste del ancho para respetar los márgenes
            backgroundColor: '#333',
            borderRadius: '10px',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}
        >
          <Tooltip title="Comandos especiales">
            <IconButton
              onClick={(event) => handleClick(event, 'commandsMenu')}
              sx={{ color: 'white', marginRight: '20px' }}  // Cambiar color a blanco y agregar margen
            >
              <KeyboardCommandKey />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl.commandsMenu}
            open={Boolean(anchorEl.commandsMenu)}
            onClose={() => handleClose('commandsMenu')}
          >
            <MenuItem onClick={() => sendSpecialCommand('Ctrl+C')}>Ctrl+C</MenuItem>
            <MenuItem onClick={() => sendSpecialCommand('Ctrl+Z')}>Ctrl+Z</MenuItem>
            <MenuItem onClick={() => sendSpecialCommand('Ctrl+L')}>Ctrl+L</MenuItem>
            <MenuItem onClick={() => sendSpecialCommand('Ctrl+X')}>Ctrl+X</MenuItem>
            <MenuItem onClick={() => sendSpecialCommand('Ctrl+O')}>Ctrl+O</MenuItem>
          </Menu>
          <Tooltip title="Archivos De Sesion">
            <IconButton
              onClick={(event) => {
                handleClick(event, 'sessionFiles');
                handleListUserFiles();
              }}
              sx={{ color: 'white', marginRight: '20px' }}  // Cambiar color a blanco y agregar margen
            >
              {sessionFilesIconRef.current}
            </IconButton>
          </Tooltip>



          <Menu
            anchorEl={anchorEl.sessionFiles}
            open={Boolean(anchorEl.sessionFiles)}
            onClose={() => handleClose('sessionFiles')}
          >
            {archivosUsuarioRef.current.length > 0 ? (
              archivosUsuarioRef.current.map((file) => (
                <MenuItem
                  key={file.id}
                  onClick={() => {
                    handleFileDownload(file.id);
                  }}
                  sx={{
                    backgroundColor: '#444',  // Color apenas más claro que el Paper
                    color: 'white',  // Texto blanco
                    '&:hover': {
                      backgroundColor: '#555',  // Color de fondo al pasar el ratón
                    }
                  }}
                >
                  {getFileIcon(file.name)}
                  <Typography sx={{ marginLeft: '10px' }}>{file.name}</Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem
                disabled
                sx={{
                  backgroundColor: '#444',  // Color apenas más claro que el Paper
                  color: 'white',  // Texto blanco
                }}
              >
                <Typography>Sin Archivos Disponibles</Typography>
              </MenuItem>
            )}
          </Menu>



          <Tooltip title="Reiniciar Maquina Objetivo">
            <IconButton sx={{ color: 'white', marginRight: '20px' }} onClick={() => {/* Reiniciar Máquina */}}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Finalizar Conexion">
            <IconButton sx={{ color: 'white' }} onClick={() => {/* Finalizar Conexión */}}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Paper>


        {transferProgress !== null && (
          <Box position="absolute" top={10} left={10} width="20%">
            <LinearProgress variant="determinate" value={transferProgress} />
            <Typography variant="caption" color="white">
              {transferProgress}%
            </Typography>
          </Box>
        )}
        <div
          ref={terminalRef}
          style={{ flex: 1, width: '100%', height: '100%', textAlign: 'left' }}
        />
      </Paper>
      {showSuccessAlert && (
        <Alert severity="success" sx={{ position: 'fixed', top: 10, right: 10 }}>
          El archivo se ha transferido con éxito.
        </Alert>
      )}
      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>Editar Archivo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Editando: {filePath}
          </DialogContentText>
          <Alert severity={isFileSaved ? "success" : "error"} sx={{ mb: 2 }}>
            {isFileSaved ? "El archivo es idéntico al remoto." : "El archivo fue modificado, si sales sin guardarlo, perderás los cambios."}
          </Alert>
          <TextField
            autoFocus
            margin="dense"
            label="Contenido del Archivo"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={20}
            value={fileContent}
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Atrás
          </Button>
          <Button onClick={handleSaveFile} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SshTerminal;
