import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import {useAuth} from "./AuthProvider";
import {obtener_carritos_de_cuenta_especifica} from "../Globales/MetodosAPIs";

// Crear un nuevo contexto para la cuenta seleccionada
const CuentaContext = createContext(null);

// Hook personalizado para usar el contexto
export function useAccount() {
  return useContext(CuentaContext);
}

// Componente provider que envuelve a los hijos y provee el estado y las funciones
export function CuentaProvider({ children }) {
  const [cuentaSeleccionada, setCuentaSeleccionada] = useState(null);
  const [carritoData, setCD] = useState([]);
  const { authTokens } = useAuth();
  const accountRef = useRef(null);

  const fetchCarritos = async (cuentaId) => {
    try {
      const resultado = await obtener_carritos_de_cuenta_especifica(cuentaId, authTokens.accessToken);
      if (resultado.status) {
        setCD(resultado.data);
      } else {
        swal("Error", resultado.mensaje, "error");
      }
    } catch (error) {
      swal("Error", "Falló la operación.", "error");
    }
  };

  const setCS = async (cuenta) => {
    setCuentaSeleccionada(cuenta);
    accountRef.current = cuenta;
    if (cuenta) {
      await fetchCarritos(cuenta.id);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (accountRef.current) {
        fetchCarritos(accountRef.current.id);
      }
    }, 60000); // 60 segundos en milisegundos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [authTokens]);

  // El valor que será accesible para los consumidores del contexto
  const value = {
    cuentaSeleccionada,
    carritoData,
    setCS,
    setCD
  };

  return (
    <CuentaContext.Provider value={value}>
      {children}
    </CuentaContext.Provider>
  );
}
