import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useAccount } from "../../Providers/CuentaProvider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {ButtonGroup, InputAdornment, MenuItem, Select, FormControl, InputLabel, FormControlLabel} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../Providers/AuthProvider";
import CarritosTable from "./Tablas/CarritoCompuesto";
import {
    asignarCarritos,
    facturarCarritos,
    obtener_carritos_de_cuenta_especifica,
    revertir_estados_carritos
} from "../../Globales/MetodosAPIs";
import swal from "sweetalert";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from '@mui/icons-material/Print';
import Button from "@mui/material/Button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Tooltip from '@mui/material/Tooltip';
import RestoreIcon from '@mui/icons-material/Restore';
import SellIcon from '@mui/icons-material/Sell';
import Checkbox from "@mui/material/Checkbox";

const CarritosPedidos = () => {
  const { setView, authTokens } = useAuth();
  const { carritoData, cuentaSeleccionada, setCD } = useAccount();
  const [ventaFiltroDesc, setVFD] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Todos');
  const [selectedEnvio, setSelectedEnvio] = useState('Todos');
  const [excluirARevisar, setExcluirARevisar] = useState(false);

  const handleCheckboxChange = (event) => {
    setExcluirARevisar(event.target.checked);
    setFilasSeleccionadas([]);  // Limpiar filas seleccionadas
  };

  const handleChangeEstado = (event) => {
    setSelectedFilter(event.target.value);
    setFilasSeleccionadas([]);  // Limpiar filas seleccionadas
  };

  const handleChangeEnvio = (event) => {
    setSelectedEnvio(event.target.value);
    setFilasSeleccionadas([]);  // Limpiar filas seleccionadas
  };

  const generarPDF = async () => {
    const facturacionExitosa = await manejarFacturacion();  // Espera a ver si la facturación fue exitosa
    if (!facturacionExitosa) return;  // Si la facturación no fue exitosa, termina la función aquí

    const doc = new jsPDF();
    const tableColumn = ["SKU", "Cantidad", "Descripción", "Venta ID"];
    const productoMap = new Map();
    let totalVentas = 0;

    carritoData.forEach(carrito => {
        if (filasSeleccionadas.includes(carrito.id)) {
            carrito.ventas.forEach(venta => {
                totalVentas += 1;  // Contar cada venta
                venta.detalles_venta.forEach(detalle => {
                    const { sku, descripcion } = detalle.producto;
                    const cantidad = detalle.cantidad;
                    const ventaId = venta.id;
                    const shortDescripcion = descripcion.length > 20 ? descripcion.slice(0, 20) : descripcion;

                    if (productoMap.has(sku)) {
                        const existingEntry = productoMap.get(sku);
                        existingEntry.cantidad += cantidad;
                        existingEntry.ventas.add(ventaId);  // Usar un Set para evitar duplicados
                    } else {
                        productoMap.set(sku, { sku, descripcion: shortDescripcion, cantidad, ventas: new Set([ventaId]) });
                    }
                });
            });
        }
    });

    // Convertir los Sets de ventas a arrays y formatear los IDs de ventas
    const formatVentaIds = (ventas) => {
        const ventaArray = Array.from(ventas);
        let formatted = '';
        for (let i = 0; i < ventaArray.length; i += 2) {
            if (i + 1 < ventaArray.length) {
                formatted += `${ventaArray[i]} -- ${ventaArray[i + 1]}\n`;
            } else {
                formatted += `${ventaArray[i]}\n`;
            }
        }
        return formatted.trim();
    };

    const tableRows = Array.from(productoMap.values())
      .sort((a, b) => a.sku.localeCompare(b.sku))
      .map(item => [
        item.sku,
        item.cantidad,
        item.descripcion,
        formatVentaIds(item.ventas)
      ]);

    // Obtener la fecha y hora actual en el formato dd-mm-yyyy HH:MM
    const now = new Date();
    const dateStr = now.toISOString().replace(/[:\-]|\.\d{3}/g, '');
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}-${String(now.getMonth() + 1).padStart(2, '0')}-${now.getFullYear()} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

    // Crear el encabezado con la cantidad total de ventas
    const headerText = `Ventas Facturadas Boxit : ${formattedDate} -- Total de Ventas incluidas: ${totalVentas}`;

    doc.setFontSize(12);  // Tamaño de letra del título
    doc.text(headerText, 14, 15);

    // Reducir el tamaño de la letra de la tabla y ajustar la altura de las filas
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: {
        fontSize: 8,  // Reducir el tamaño de la letra en un 30%
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },  // Ajustar el padding de las celdas
        fontStyle: 'bold',  // Hacer la letra más bold
        textColor: [50, 50, 50],  // Gris más oscuro
        valign: 'middle'  // Centrar verticalmente el texto
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [50, 50, 50],  // Gris más oscuro
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontStyle: 'bold',  // Hacer la letra de la cabecera más bold
        valign: 'middle'  // Centrar verticalmente el texto
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        textColor: [50, 50, 50],  // Gris más oscuro
        valign: 'middle'  // Centrar verticalmente el texto
      },
      columnStyles: {
        1: { halign: 'center' },  // Alinear la columna "Cantidad" en el centro
        3: { cellWidth: 'wrap' }  // Ensanchar la columna 'Venta ID' si es necesario
      },
      willDrawCell: function (data) {
        if (data.row.index % 2 === 0) {
          doc.setFillColor(225, 235, 235);  // Fondo gris claro en filas alternas
        } else {
          doc.setFillColor(255, 255, 255);  // Fondo blanco para las otras filas
        }
        if (data.section === 'body') {
          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');  // Dibuja el fondo
        }
      },
      didParseCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.raw.includes('\n')) {
          data.cell.styles.cellPadding = { top: 2, right: 2, bottom: 2, left: 2 };
        }
      }
    });

    doc.save(`reporte_${cuentaSeleccionada.usuario}__${formattedDate}.pdf`);
  };

  const manejarFacturacion = async () => {
    if (!filasSeleccionadas.length) {
      swal("Error", "No hay carritos seleccionados para facturar.", "warning");
      return false;
    }

    try {
      const resultado = await facturarCarritos(authTokens.accessToken, filasSeleccionadas);
      if (resultado.status) {
        swal("Éxito", "Carritos facturados exitosamente", "success");
        setFilasSeleccionadas([]);
        setRefresh(Math.random());
        return true;  // Devuelve true si la facturación fue exitosa
      } else {
        swal("Error", resultado.data.message, "error");
        return false;  // Devuelve false si hubo un error
      }
    } catch (error) {
      swal("Error", "Falló la operación de facturación.", "error");
      return false;  // Devuelve false si hubo una excepción
    }
  };

  useEffect(() => {
    console.log(carritoData);
  }, []);

  const manejarSeleccionCuenta = async () => {
    try {
      const resultado = await obtener_carritos_de_cuenta_especifica(cuentaSeleccionada.id, authTokens.accessToken);
      if (resultado.status) {
        // Operación exitosa
        setCD(resultado.data);
      } else {
        swal("Error", resultado.mensaje, "error");
      }
    } catch (error) {
      swal("Error", "Falló la operación.", "error");
    }
  };

  const manejarReversion = async () => {
    if (!filasSeleccionadas.length) {
      swal("Error", "No hay carritos seleccionados para revertir.", "warning");
      return;
    }

    try {
      const resultado = await revertir_estados_carritos(authTokens.accessToken, filasSeleccionadas);
      if (resultado.status) {
        swal("Éxito", "Estados de carritos revertidos exitosamente", "success");
        setFilasSeleccionadas([]);
        setRefresh(Math.random());  // Refrescar la vista para mostrar los estados actualizados
      } else {
        swal("Error", resultado.mensaje, "error");
      }
    } catch (error) {
      swal("Error", "Falló la operación de revertir estados.", "error");
    }
  };

  useEffect(() => {
    manejarSeleccionCuenta();
  }, [refresh]);

  const filtrarCarritos = () => {
    return carritoData.filter(carrito => {
      const filtroPorEstado = selectedFilter === 'Todos' || carrito.estado === selectedFilter.toLowerCase().replace(' ', '_');
      const filtroPorId = ventaFiltroDesc ? carrito.id.toString().includes(ventaFiltroDesc) : true;
      const filtroPorVentaId = ventaFiltroDesc ? carrito.ventas.some(venta => venta.id.toString().includes(ventaFiltroDesc)) : true;
      const filtroPorEnvio = selectedEnvio === 'Todos' || carrito.detalles_envio.tipo_de_envio === selectedEnvio.toLowerCase().replace(' ', '_');
      const filtroPorComboVirtual = !excluirARevisar || !carrito.ventas.some(venta =>
        venta.detalles_venta.some(detalle => detalle.producto.es_combo_virtual)
      );
      return filtroPorEstado && (filtroPorId || filtroPorVentaId) && filtroPorEnvio && filtroPorComboVirtual;
    });
  };

  const estados = [
    { id: 'Todos', desc: 'Todos' },
    { id: 'a_facturar', desc: 'Para Facturar' },
    { id: 'a_asignar', desc: 'Para Asignar' },
    { id: 'asignado', desc: 'Asignado' },
    { id: 'en_preparacion', desc: 'En Preparación' },
    { id: 'embalado', desc: 'Embalado' },
    { id: 'cancelado', desc: 'Cancelado' },
    { id: 'en_observacion', desc: 'En Observación' }
  ];

  const tiposEnvio = [
    { id: 'Todos', desc: 'Todos' },
    { id: 'cross_docking', desc: 'Colecta' },
    { id: 'fulfillment', desc: 'Envio Full' },
    { id: 'self_service', desc: 'Envio Flex' },
    { id: 'acuerdo_con_comprador', desc: 'Acuerdo con Comprador' }
  ];

  const preasignarCarritos = async () => {
    if (!filasSeleccionadas.length) {
      swal("Error", "No hay carritos seleccionados para asignar.", "warning");
      return;
    }

    try {
      const resultado = await asignarCarritos(authTokens.accessToken, filasSeleccionadas);
      if (resultado.status) {
        swal("Éxito", "Carritos asignados exitosamente", "success");
        setFilasSeleccionadas([]);
        setRefresh(Math.random());  // Refrescar la vista para mostrar los estados actualizados
      } else {
        swal("Error", resultado.mensaje, "error");
      }
    } catch (error) {
      swal("Error", "Falló la operación de asignación.", "error");
    }
  };

  const contarVentasSeleccionadas = () => {
    return filasSeleccionadas.reduce((acc, carritoId) => {
      const carrito = carritoData.find(c => c.id === carritoId);
      return acc + (carrito ? carrito.ventas.length : 0);
    }, 0);
  };

  return (
    <Container maxWidth="lg" style={{ height: '100vh' }}>
      <Box sx={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: '10%', p: 3, width: "100%" }}>
          <Box sx = {{display: 'flex', flexDirection: 'row', justifyContent:"center" }}>
            <TextField
              sx={{ flexGrow: 1 }}
              placeholder="Buscar Venta"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={ventaFiltroDesc}
              onChange={(e) => setVFD(e.target.value)}
            />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
                <InputLabel id="estado-label">Estado</InputLabel>
                <Select
                  labelId="estado-label"
                  id="estado-select"
                  value={selectedFilter}
                  label="Estado"
                  onChange={handleChangeEstado}
                >
                  {estados.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id}>{estado.desc}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
                <InputLabel id="envio-label">Tipo de Envío</InputLabel>
                <Select
                  labelId="envio-label"
                  id="envio-select"
                  value={selectedEnvio}
                  label="Tipo de Envío"
                  onChange={handleChangeEnvio}
                >
                  {tiposEnvio.map((envio) => (
                    <MenuItem key={envio.id} value={envio.id}>{envio.desc}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Tooltip title="Regresar al Estado Anterior">
                  <span>
                    <Button onClick={manejarReversion} disabled={!filasSeleccionadas.length}>
                      <RestoreIcon />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="Facturar">
                  <span>
                    <Button onClick={generarPDF} disabled={!filasSeleccionadas.length}>
                      <PrintIcon />
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title="Asignar">
                  <span>
                    <Button onClick={preasignarCarritos} disabled={!filasSeleccionadas.length}>
                      <SendIcon />
                    </Button>
                  </span>
                </Tooltip>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '90%', p: 3, width: "100%" }}>
          <Box sx={{ height: '90%', p: 3, width: "100%" }}>
              <Paper elevation={8} sx={{ height: '100%', maxHeight: "600px", width: "100%" }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                      <Tooltip title="Ventas Seleccionadas">
                          <Paper
                              sx={{
                                  minWidth: 100,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontWeight: 'bold',
                                  bgcolor: 'primary.main',
                                  color: 'white',
                                  p: 1
                              }}
                          >
                              <SellIcon sx={{ marginRight: 1, color: 'white' }} /> {contarVentasSeleccionadas()}
                          </Paper>
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={excluirARevisar}
                            onChange={handleCheckboxChange}
                            color="primary"
                          />
                        }
                        label="Excluir 'A REVISAR'"
                        sx={{ marginLeft: 2 }}
                      />
                  </Box>
                  <CarritosTable
                      carritos={filtrarCarritos()}
                      setRefresh={setRefresh}
                      filasSeleccionadas={filasSeleccionadas}
                      setFilasSeleccionadas={setFilasSeleccionadas}
                  />
              </Paper>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CarritosPedidos;
