import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registro de componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      position: 'top', // Ajusta según necesidad: 'top', 'right', 'bottom', 'left'
    },
    title: {
      text: 'Ventas Segregadas por Usuario',
      display: true,
    },
  },
  responsive: true, // Asegura que el gráfico sea responsivo
  scales: {
    x: {
      stacked: true, // Configuración para las barras apiladas
    },
    y: {
      stacked: true, // Configuración para las barras apiladas
    }
  }
};

const GraficoTiemposPorUsuario = ({ data }) => {
  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default GraficoTiemposPorUsuario;
