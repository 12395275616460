import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Box, CircularProgress, IconButton, Collapse
} from '@mui/material';
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Swal from 'sweetalert2';
import { crearGrupo, listarPermisos, eliminarGrupo } from '../../../Globales/MetodosAPIs';

const TablaGrupo = ({ data, accessToken, refresh }) => {
  const [open, setOpen] = useState(false);
  const [nombreGrupo, setNombreGrupo] = useState('');
  const [permisosDisponibles, setPermisosDisponibles] = useState([]);
  const [permisosSeleccionados, setPermisosSeleccionados] = useState([]);
  const [selectedAvailablePermiso, setSelectedAvailablePermiso] = useState(null);
  const [selectedGroupPermiso, setSelectedGroupPermiso] = useState(null);
  const [openRows, setOpenRows] = useState({});

  useEffect(() => {
    fetchPermisos();
  }, []);

  const fetchPermisos = async () => {
    const response = await listarPermisos(accessToken);
    if (response.status) {
      setPermisosDisponibles(response.data);
    } else {
      console.error(response.mensaje);
    }
  };

  const handleAgregarGrupo = async () => {
    const permisos = permisosSeleccionados.map(p => p.id);
    const response = await crearGrupo(accessToken, { nombre: nombreGrupo, permisos });
    if (response.status) {
      setOpen(false);
      refresh();
    } else {
      console.error(response.mensaje);
    }
  };

  const handleEliminarGrupo = async (grupoId) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo'
    });

    if (result.isConfirmed) {
      const response = await eliminarGrupo(accessToken, grupoId);
      if (response.status) {
        refresh();
      } else {
        console.error(response.mensaje);
        Swal.fire(
          'Error',
          'No se pudo eliminar el grupo.',
          'error'
        );
      }
    }
  };

  const handleSeleccionarPermiso = (permiso) => {
    setSelectedAvailablePermiso(permiso);
    setSelectedGroupPermiso(null);
  };

  const handleQuitarPermiso = (permiso) => {
    setSelectedGroupPermiso(permiso);
    setSelectedAvailablePermiso(null);
  };

  const handleAddPermiso = () => {
    if (selectedAvailablePermiso) {
      setPermisosDisponibles((prev) => prev.filter(p => p.id !== selectedAvailablePermiso.id));
      setPermisosSeleccionados((prev) => [...prev, selectedAvailablePermiso]);
      setSelectedAvailablePermiso(null);
    }
  };

  const handleRemovePermiso = () => {
    if (selectedGroupPermiso) {
      setPermisosSeleccionados((prev) => prev.filter(p => p.id !== selectedGroupPermiso.id));
      setPermisosDisponibles((prev) => [...prev, selectedGroupPermiso]);
      setSelectedGroupPermiso(null);
    }
  };

  const toggleRow = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <TableContainer component={Paper}>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Agregar Grupo
        </Button>
      </Box>
      {data.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nombre del Grupo</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((grupo) => (
              <>
                <TableRow key={grupo.id}>
                  <TableCell>
                    <IconButton onClick={() => toggleRow(grupo.id)}>
                      {openRows[grupo.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{grupo.name}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleEliminarGrupo(grupo.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={openRows[grupo.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Permisos del Grupo
                        </Typography>
                        <Table size="small" aria-label="permisos">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre del Permiso</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {grupo.permissions?.map((permiso) => (
                              <TableRow key={permiso.id}>
                                <TableCell>{permiso.name}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Agregar Grupo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Grupo"
            type="text"
            fullWidth
            value={nombreGrupo}
            onChange={(e) => setNombreGrupo(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Box flex={1} mx={1}>
              <Typography variant="h6" gutterBottom>Permisos Disponibles</Typography>
              <TableContainer component={Paper} style={{ maxHeight: 500, overflowY: 'auto' }}>
                <Table size="small">
                  <TableBody>
                    {permisosDisponibles.map((permiso) => (
                      <TableRow
                        key={permiso.id}
                        onClick={() => handleSeleccionarPermiso(permiso)}
                        sx={{ bgcolor: selectedAvailablePermiso?.id === permiso.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit' }}
                      >
                        <TableCell>{permiso.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={2}>
              <Button onClick={handleAddPermiso} disabled={!selectedAvailablePermiso} variant="contained" style={{ marginBottom: '8px' }}>
                <KeyboardArrowRightIcon />
              </Button>
              <Button onClick={handleRemovePermiso} disabled={!selectedGroupPermiso} variant="contained" style={{ marginTop: '8px' }}>
                <KeyboardArrowLeftIcon />
              </Button>
            </Box>
            <Box flex={1} mx={1}>
              <Typography variant="h6" gutterBottom>Permisos del Grupo</Typography>
              <TableContainer component={Paper} style={{ maxHeight: 500, overflowY: 'auto' }}>
                <Table size="small">
                  <TableBody>
                    {permisosSeleccionados.map((permiso) => (
                      <TableRow
                        key={permiso.id}
                        onClick={() => handleQuitarPermiso(permiso)}
                        sx={{ bgcolor: selectedGroupPermiso?.id === permiso.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit' }}
                      >
                        <TableCell>{permiso.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAgregarGrupo} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default TablaGrupo;
