import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";

import {loginF, obtenerPermisosUsuario, refresh as refreshTokens} from "../Globales/MetodosAPIs";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authTokens, setAuthTokens] = useState(() => {
    // Recuperar tokens almacenados del localStorage
    const token = localStorage.getItem('authTokens');
    return token ? JSON.parse(token) : { accessToken: null, refreshToken: null };
  });

  const [superuser,setSU] = useState(false)
  const [showVerification,setShowVerification] = useState(false)
  const [username, setUsername] = useState(() => {
    const storedUsername = localStorage.getItem("username");
    return storedUsername || "Desconocido";
  });
  const [permisos, setPer] = useState(() => {
    const storedPermisos = localStorage.getItem('permisos');
    return storedPermisos ? JSON.parse(storedPermisos) : false;
  });
  const [actualView, setView] = useState('login');
  const [loginError, setLoginError] = useState(null);
  const [expirationTime, setExpirationTime] = useState(null);

  useEffect(() => {
    const loadAuthData = async () => {
      const storedTokens = localStorage.getItem('authTokens');
      if (storedTokens) {
        const parsedTokens = JSON.parse(storedTokens);
        setAuthTokens(parsedTokens);
        setExpirationTime(jwtDecode(parsedTokens.accessToken).exp);
      }
      const storedPermisos = localStorage.getItem('permisos');
      if (storedPermisos) {
        setPer(JSON.parse(storedPermisos));
      }
      const storedUsername = localStorage.getItem("username");
      if (storedUsername) {
        setUsername(storedUsername);
      }
    };

    loadAuthData();
  }, []);


   const capitalizeUsername = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  useEffect(() => {
    console.log("Se evalua si hay accessToken")
    if (authTokens.accessToken) {
      localStorage.setItem('authTokens', JSON.stringify(authTokens));
      setExpirationTime(jwtDecode(authTokens.accessToken).exp);

      // Asegurarse de que se ha obtenido la información de permisos antes de establecer la vista
      if (permisos) {
        // Aquí se verifica si el array de permisos contiene 'puede_ver_cuentas'
        if (permisos && permisos.includes('puede_ver_cuentas')) {
          setView('dashboard');
        } else {
          setView('embalaje');
        }
      } else {
        // Si no hay permisos disponibles, podría redirigirse a una vista de error o permanecer en la vista actual
        setView('login');
      }
    }
  }, [authTokens, permisos]);


  useEffect(() => {
    if (expirationTime) {
      const currentTime = Date.now() / 1000;
      const timeLeft = expirationTime - currentTime;
      if (timeLeft < 300) { // 5 minutes before token expires
        refresh();
      }
    }
  }, [expirationTime]);

  const login = async (username, password, huella_digital, verification_code) => {
    try {
      const response = await loginF(username, password, huella_digital, verification_code);
      console.log("Respuesta recibida al pasar username y password: ", response);
      if (response.data && response.data.access_token && response.data.refresh) {
        handleSuccessfulLogin(response);
      } else if (response.data && response.data.status === "awaiting_verification") {
        setShowVerification(true); // Asegúrate de que esta es la condición correcta
        setLoginError(response.data.message);
      } else {
        setLoginError(response.mensaje || 'Error desconocido');
      }
    } catch (err) {
      console.log("Apareció un error: ", err);
      handleLoginError(err);
    }
  };


  // Helper functions to clean up the code
  async function handleSuccessfulLogin(response) {
    const formattedUsername = capitalizeUsername(response.data.username);
    setUsername(formattedUsername);
    localStorage.setItem("username", formattedUsername);
    setAuthTokens({accessToken: response.data.access_token, refreshToken: response.data.refresh});
    setSU(response.data.super);
    setLoginError(null);
    const permisosResponse = await obtenerPermisosUsuario(response.data.access_token);
    const permisos = permisosResponse.data.permissions;
    setPer(permisos); // Guarda los permisos en el estado
    console.log("Guardamos los permisos del usuario: ",permisos)
    localStorage.setItem('permisos', JSON.stringify(permisos)); // Guarda los permisos en localStorage
  }

  function handleLoginError(err) {
    console.error('Error procesando el login:', err.message || 'Error desconocido');
    setLoginError('Error procesando el login');
  }


  const logout = () => {
    // Eliminar los tokens del localStorage
    localStorage.removeItem('authTokens');
    // Actualizar el estado para reflejar que el usuario no está autenticado
    setAuthTokens({ accessToken: null, refreshToken: null });
    // Cambiar la vista actual a 'login'
    setView('login');
    // Limpiar el mensaje de error, si es necesario
    setLoginError(null);
  };


  const refresh = async () => {
    try {
      const newTokens = await refreshTokens(authTokens.refreshToken);
      setAuthTokens({ accessToken: newTokens.access, refreshToken: newTokens.refresh });
      setLoginError(null);
    } catch (error) {
      setLoginError('No se pudo refrescar el token');
    }
  };

  const value = {
    authTokens,
    login,
    showVerification,
    logout,
    refresh,
    superuser,
    permisos,
    username,
    loginError,
    actualView,
    setView
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
