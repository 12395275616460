import React, { useState, useEffect } from 'react';
import {
  MenuItem, Select, Container, Typography, CircularProgress,
  Snackbar, Alert, Paper, Box
} from '@mui/material';
import {
  listarImpresoras, listarPuestos, listarUsuarios,
  listarServidoresImpresion, listarGrupos
} from '../../Globales/MetodosAPIs';
import { useAuth } from '../../Providers/AuthProvider';
import TablaPuestos from './Tablas/TablaPuesto';
import TablaUsuarios from './Tablas/TablaUsuarios';
import TablaImpresoras from './Tablas/TablaImpresora';
import TablaServidoresCups from "./Tablas/TablaServidorCups";
import TablaGrupo from "./Tablas/TablaGrupo";

const UserAdmin = () => {
  const { authTokens } = useAuth();
  const [view, setView] = useState('Usuarios');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchData = async (fetchFunction) => {
    setLoading(true);
    setError(null);
    setData([]); // Reset data to show loading indicator
    try {
      const response = await fetchFunction(authTokens.accessToken);
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (view === 'Puestos') {
      fetchData(listarPuestos);
    } else if (view === 'Usuarios') {
      fetchData(listarUsuarios);
    } else if (view === 'Impresoras') {
      fetchData(listarImpresoras);
    } else if (view === 'ServidoresCUPS') {
      fetchData(listarServidoresImpresion);
    } else if (view === 'Grupos') {
      fetchData(listarGrupos);
    }
  }, [view, refreshKey]);

  const refresh = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Panel de Administración</Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="subtitle1" align="center">Tabla a visualizar</Typography>
          <Select value={view} onChange={(e) => setView(e.target.value)} style={{ minWidth: '200px' }}>
            <MenuItem value="Puestos">Puestos</MenuItem>
            <MenuItem value="Usuarios">Usuarios</MenuItem>
            <MenuItem value="Impresoras">Impresoras</MenuItem>
            <MenuItem value="ServidoresCUPS">Servidores CUPS</MenuItem>
            <MenuItem value="Grupos">Grupos</MenuItem>
          </Select>
        </Box>
      </Paper>

      {loading && <CircularProgress />}
      {error && (
        <Snackbar open={true} autoHideDuration={6000}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      )}

      <Paper elevation={3} style={{ padding: '16px' }}>
        {view === 'Puestos' && <TablaPuestos data={data} accessToken={authTokens.accessToken} refresh={refresh} />}
        {view === 'Usuarios' && <TablaUsuarios data={data} accessToken={authTokens.accessToken} refresh={refresh} />}
        {view === 'Impresoras' && <TablaImpresoras data={data} accessToken={authTokens.accessToken} refresh={refresh} />}
        {view === 'ServidoresCUPS' && <TablaServidoresCups data={data} accessToken={authTokens.accessToken} refresh={refresh} />}
        {view === 'Grupos' && <TablaGrupo data={data} accessToken={authTokens.accessToken} refresh={refresh} />}
      </Paper>
    </Container>
  );
};

export default UserAdmin;
