import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton,
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    CircularProgress,
    Collapse,
    Typography,
    ButtonGroup
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Swal from 'sweetalert2';
import { cambiarEstadoActivoDeUsuario, agregarUsuario, eliminarUsuario, listarGrupos, agregarUsuarioGrupo, quitarUsuarioGrupo, actualizarUsuario } from '../../../Globales/MetodosAPIs';
import SaveIcon from "@mui/icons-material/Save";

const CustomRow = ({ usuario, accessToken, refresh, handleToggleActive, handleDelete, isOpen, toggleOpen }) => {
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedAvailableGroup, setSelectedAvailableGroup] = useState(null);
    const [selectedUserGroup, setSelectedUserGroup] = useState(null);
    const [email, setEmail] = useState(usuario.email);
    const [password, setPassword] = useState('');
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchAvailableGroups();
        }
    }, [isOpen]);

    const fetchAvailableGroups = async () => {
        const response = await listarGrupos(accessToken);
        if (response.status) {
            const gruposDisponibles = response.data.filter(g => !usuario.groups?.some(ug => ug.id === g.id));
            setAvailableGroups(gruposDisponibles);
        } else {
            console.error(response.mensaje);
        }
    };

    const handleSelectAvailableGroup = (group) => {
        setSelectedAvailableGroup(group);
        setSelectedUserGroup(null);
    };

    const handleSelectUserGroup = (group) => {
        setSelectedUserGroup(group);
        setSelectedAvailableGroup(null);
    };

    const handleAddGroup = async () => {
        if (selectedAvailableGroup) {
            const response = await agregarUsuarioGrupo(accessToken, usuario.id, selectedAvailableGroup.id);
            if (response.status) {
                fetchAvailableGroups();
                refresh();
            }
            setSelectedAvailableGroup(null);
        }
    };

    const handleRemoveGroup = async () => {
        if (selectedUserGroup) {
            const response = await quitarUsuarioGrupo(accessToken, usuario.id, selectedUserGroup.id);
            if (response.status) {
                fetchAvailableGroups();
                refresh();
            }
            setSelectedUserGroup(null);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = async () => {
        const response = await actualizarUsuario(accessToken, usuario.id, email, password || null);
        if (response.status) {
            setEditMode(false);
            setPassword('');
            refresh();
        } else {
            console.error(response.mensaje);
        }
    };

    const handleCancel = () => {
        setEmail(usuario.email);
        setPassword('');
        setEditMode(false);
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => toggleOpen(usuario.id)}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{usuario.username}</TableCell>
                <TableCell>
                    {editMode ? (
                        <TextField value={email} onChange={handleEmailChange} />
                    ) : (
                        email
                    )}
                </TableCell>
                <TableCell>
                    {editMode ? (
                        <TextField
                            label="Contraseña"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    ) : (
                        <TextField
                            label="Contraseña"
                            type="password"
                            value="********"
                            disabled
                        />
                    )}
                </TableCell>
                <TableCell>{usuario.is_active ? 'Activo' : 'Bloqueado'}</TableCell>
                <TableCell>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        {editMode ? (
                            <Button onClick={handleSave}>
                                <SaveIcon />
                            </Button>
                        ) : (
                            <Button onClick={handleEdit}>
                                <EditIcon />
                            </Button>
                        )}
                        <Button onClick={() => handleToggleActive(usuario.id, usuario.is_active)} color="error">
                            {usuario.is_active ? <BlockIcon /> : <LockOpenIcon />}
                        </Button>
                        <Button onClick={() => handleDelete(usuario.id)} color="error">
                            <DeleteIcon />
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box ml={2} border={1} borderRadius={5} p={2}>
                            <Box display="flex" justifyContent="space-between">
                                <Box flex={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Grupos Disponibles
                                    </Typography>
                                    <TableContainer component={Paper} style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Grupo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {availableGroups.map(group => (
                                                    <TableRow key={group.id} onClick={() => handleSelectAvailableGroup(group)} sx={{ bgcolor: selectedAvailableGroup && selectedAvailableGroup.id === group.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit' }}>
                                                        <TableCell>{group.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={2}>
                                    <Button variant="contained" onClick={handleAddGroup} disabled={!selectedAvailableGroup} sx={{ mb: 1 }}>
                                        <KeyboardArrowRightIcon />
                                    </Button>
                                    <Button variant="contained" onClick={handleRemoveGroup} disabled={!selectedUserGroup}>
                                        <KeyboardArrowLeftIcon />
                                    </Button>
                                </Box>
                                <Box flex={1}>
                                    <Typography variant="h6" gutterBottom>
                                        Grupos del Usuario
                                    </Typography>
                                    <TableContainer component={Paper} style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Grupo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {usuario.groups?.map(group => (
                                                    <TableRow key={group.id} onClick={() => handleSelectUserGroup(group)} sx={{ bgcolor: selectedUserGroup && selectedUserGroup.id === group.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit' }}>
                                                        <TableCell>{group.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
const TablaUsuarios = ({ data, accessToken, refresh }) => {
    const [open, setOpen] = useState(false);
    const [newUsuario, setNewUsuario] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const [openRows, setOpenRows] = useState({});

    useEffect(() => {
        setOpenRows({});
    }, [data]);

    const handleToggleActive = async (id, isActive) => {
        const response = await cambiarEstadoActivoDeUsuario(accessToken, id);
        if (response.status) {
            refresh();
        } else {
            console.error(response.mensaje);
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        });

        if (result.isConfirmed) {
            const response = await eliminarUsuario(accessToken, id);
            if (response.status) {
                refresh();
            } else {
                console.error(response.mensaje);
                Swal.fire(
                    'Error',
                    'No se pudo eliminar el usuario.',
                    'error'
                );
            }
        }
    };

    const validateFields = () => {
        let tempErrors = {};
        if (!newUsuario.username) tempErrors.username = "Username es requerido";
        if (!newUsuario.email) tempErrors.email = "Email es requerido";
        if (!newUsuario.password) tempErrors.password = "Contraseña es requerida";
        if (newUsuario.password !== newUsuario.confirmPassword) tempErrors.confirmPassword = "Las contraseñas no coinciden";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleAdd = async () => {
        if (validateFields()) {
            const response = await agregarUsuario(accessToken, newUsuario);
            if (response.status) {
                setOpen(false);
                refresh();
            } else {
                console.error(response.mensaje);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUsuario({ ...newUsuario, [name]: value });
    };

    const toggleOpen = (id) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [id]: !prevOpenRows[id]
        }));
    };

    return (
        <TableContainer component={Paper}>
            <Box display="flex" justifyContent="flex-end" m={2}>
                <Tooltip title="Agregar Usuario">
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                        Agregar Usuario
                    </Button>
                </Tooltip>
            </Box>
            {data.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((usuario) => (
                            <CustomRow
                                key={usuario.id}
                                usuario={usuario}
                                accessToken={accessToken}
                                refresh={refresh}
                                handleToggleActive={handleToggleActive}
                                handleDelete={handleDelete}
                                isOpen={!!openRows[usuario.id]}
                                toggleOpen={toggleOpen}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Agregar Usuario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Complete los siguientes campos para agregar un nuevo usuario.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Usuario"
                        type="text"
                        fullWidth
                        name="username"
                        value={newUsuario.username}
                        onChange={handleChange}
                        error={!!errors.username}
                        helperText={errors.username}
                    />
                    <TextField
                        margin="dense"
                        label="Email (2FA)"
                        type="email"
                        fullWidth
                        name="email"
                        value={newUsuario.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        margin="dense"
                        label="Contraseña"
                        type="password"
                        fullWidth
                        name="password"
                        value={newUsuario.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                    <TextField
                        margin="dense"
                        label="Confirmar Contraseña"
                        type="password"
                        fullWidth
                        name="confirmPassword"
                        value={newUsuario.confirmPassword}
                        onChange={handleChange}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
};

export default TablaUsuarios;

