import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  Button,
  TextField,
  Typography,
  Box
} from '@mui/material';

const ObservationDialog = ({ open, onClose, onSubmit }) => {
  const [motivo, setMotivo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [errors, setErrors] = useState({ motivo: '', descripcion: '' });

  const motivos = [
    { id: 'falta_stock', descripcion: "Falta de Stock" },
    { id: 'venta_cancelada', descripcion: "Venta Cancelada" },
    { id: 'rotura', descripcion: "Rotura de Escáner / Impresora" },
    { id: 'otros', descripcion: "Otro Motivo" }
  ];

  const handleSubmit = () => {
    // Validar formulario
    const errors = {};
    if (!motivo) errors.motivo = 'Seleccione un motivo';
    if (descripcion.length < 10) errors.descripcion = 'La descripción debe tener al menos 10 caracteres';
    if (descripcion.length > 255) errors.descripcion = 'La descripción debe tener como máximo 255 caracteres';

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      onSubmit({ motivo, descripcion });
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '70vw',
          height: '80vh'
        }
      }}
    >
      <DialogTitle>Formulario de Observación</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography>Motivo:</Typography>
          <Select
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            error={!!errors.motivo}
          >
            {motivos.map((motivo) => (
              <MenuItem key={motivo.id} value={motivo.id}>
                {motivo.descripcion}
              </MenuItem>
            ))}
          </Select>
          {errors.motivo && <Typography color="error">{errors.motivo}</Typography>}

          <Typography>Descripción:</Typography>
          <TextField
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            multiline
            rows={5}
            error={!!errors.descripcion}
            helperText={errors.descripcion}
            inputProps={{ maxLength: 255 }}
            placeholder="Ingrese una descripción detallada"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Enviar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObservationDialog;
