import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';
import { agregarImpresora, listarServidoresImpresion, listarEmpresasDisponibles, modificarImpresora, eliminarImpresora } from '../../../Globales/MetodosAPIs';

const TablaImpresoras = ({ data, accessToken, refresh }) => {
  const [open, setOpen] = useState(false);
  const [editIdx, setEditIdx] = useState(-1);
  const [newImpresora, setNewImpresora] = useState({
    nombre: '',
    direccionMac: '',
    servidorCUPS: '',
    empresa: ''
  });
  const [editImpresora, setEditImpresora] = useState({});
  const [servidores, setServidores] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const responseServidores = await listarServidoresImpresion(accessToken);
        const responseEmpresas = await listarEmpresasDisponibles(accessToken);

        if (responseServidores.status) {
          setServidores(responseServidores.data);
        } else {
          console.error(responseServidores.mensaje);
        }

        if (responseEmpresas.status) {
          setEmpresas(responseEmpresas.data);
        } else {
          console.error(responseEmpresas.mensaje);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      const response = await eliminarImpresora(accessToken, id);
      if (response.status) {
        Swal.fire(
          'Eliminado',
          'La impresora ha sido eliminada.',
          'success'
        );
        refresh();  // Refrescar la tabla después de eliminar una impresora
      } else {
        Swal.fire(
          'Error',
          response.mensaje,
          'error'
        );
      }
    }
  };

  const handleAdd = async () => {
    const response = await agregarImpresora(accessToken, newImpresora);
    if (response.status) {
      console.log(response.mensaje);
      setOpen(false);
      refresh();  // Refrescar la tabla después de agregar una impresora
    } else {
      console.error(response.mensaje);
    }
  };

  const handleEdit = (impresora) => {
    setEditImpresora({
      ...impresora,
      servidorCUPS: impresora.servidor?.id || ''
    });
    setEditIdx(impresora.id);
  };

  const handleSave = async () => {
    const response = await modificarImpresora(accessToken, {
      id: editImpresora.id,
      servidorCUPS: editImpresora.servidorCUPS,
      descripcion: editImpresora.descripcion,
      direccion_mac: editImpresora.direccion_mac
    });
    if (response.status) {
      console.log(response.mensaje);
      setEditIdx(-1);
      refresh();  // Refrescar la tabla después de guardar cambios
    } else {
      console.error(response.mensaje);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditImpresora({ ...editImpresora, [name]: value });
  };

  const handleDialogChange = (e) => {
    const { name, value } = e.target;
    setNewImpresora({ ...newImpresora, [name]: value });
  };

  return (
    <TableContainer component={Paper}>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Tooltip title="Agregar Impresora">
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
            Agregar Impresora
          </Button>
        </Tooltip>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Descripción</TableCell>
            <TableCell>Dirección MAC</TableCell>
            <TableCell>Servidor</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            data.map((impresora) => (
              <TableRow key={impresora.id}>
                <TableCell>
                  {editIdx === impresora.id ? (
                    <TextField
                      value={editImpresora.descripcion}
                      onChange={(e) => handleChange({ target: { name: 'descripcion', value: e.target.value } })}
                      fullWidth
                    />
                  ) : (
                    impresora.descripcion
                  )}
                </TableCell>
                <TableCell>
                  {editIdx === impresora.id ? (
                    <TextField
                      value={editImpresora.direccion_mac}
                      onChange={(e) => handleChange({ target: { name: 'direccion_mac', value: e.target.value } })}
                      fullWidth
                    />
                  ) : (
                    impresora.direccion_mac
                  )}
                </TableCell>
                <TableCell>
                  {editIdx === impresora.id ? (
                    <Select
                      value={editImpresora.servidorCUPS || ''}
                      onChange={(e) => handleChange({ target: { name: 'servidorCUPS', value: e.target.value } })}
                      fullWidth
                    >
                      {servidores.map((servidor) => (
                        <MenuItem key={servidor.id} value={servidor.id}>
                          <Typography>{servidor.descripcion}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    impresora.servidor?.descripcion || 'N/A'
                  )}
                </TableCell>
                <TableCell>
                  {impresora.empresa ? impresora.empresa : 'N/A'}
                </TableCell>
                <TableCell>
                  {editIdx === impresora.id ? (
                    <Tooltip title="Guardar Cambios">
                      <IconButton onClick={handleSave} color="primary">
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Editar Impresora">
                      <IconButton onClick={() => handleEdit(impresora)} color="secondary">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Eliminar Impresora">
                    <IconButton onClick={() => handleDelete(impresora.id)} color="secondary">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Agregar Impresora</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete los siguientes campos para agregar una nueva impresora.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre"
            type="text"
            fullWidth
            name="nombre"
            value={newImpresora.nombre}
            onChange={handleDialogChange}
          />
          <TextField
            margin="dense"
            label="Dirección MAC"
            type="text"
            fullWidth
            name="direccionMac"
            value={newImpresora.direccionMac}
            onChange={handleDialogChange}
          />
          <Typography variant="subtitle1" gutterBottom>
            Servidor CUPS
          </Typography>
          <Select
            margin="dense"
            fullWidth
            name="servidorCUPS"
            value={newImpresora.servidorCUPS}
            onChange={handleDialogChange}
          >
            {servidores.map((servidor) => (
              <MenuItem key={servidor.id} value={servidor.id}>
                <Typography>{servidor.descripcion}</Typography>
              </MenuItem>
            ))}
          </Select>
          <Typography variant="subtitle1" gutterBottom>
            Empresa
          </Typography>
          <Select
            margin="dense"
            fullWidth
            name="empresa"
            value={newImpresora.empresa}
            onChange={handleDialogChange}
          >
            {empresas.map((empresa) => (
              <MenuItem key={empresa.id} value={empresa.id}>
                <Typography>{empresa.razon_social}</Typography>
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAdd} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default TablaImpresoras;
