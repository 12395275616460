import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';

// Registro de componentes necesarios de Chart.js para el gráfico Doughnut
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const options = {
  plugins: {
    legend: {
      position: 'top', // Ajusta según necesidad: 'top', 'right', 'bottom', 'left'
    },
    title: {
      display: true,
      text: 'Tiempos por Usuario ( Etapa de Preparacion)', // Asegúrate de que el título sea descriptivo
    },
  },
  responsive: true, // Asegura que el gráfico sea responsivo
  maintainAspectRatio: false, // Permite que el gráfico no mantenga la proporción de aspecto (útil para responsividad)
};

const GraficoDonaPorUsuario = ({ data }) => {
  return (
    <div style={{ height: '400px' }}>
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default GraficoDonaPorUsuario;
