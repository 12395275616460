import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from "../../Providers/AuthProvider";
import {useEffect} from "react"; // Verifica la ruta

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

function generarHuellaDigital() {
  // Funciones auxiliares para obtener partes de la huella digital
  function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.textBaseline = 'top';
    ctx.font = '14px Arial';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText('Hello, world', 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText('Hello, world', 4, 17);
    return canvas.toDataURL();
  }

  function getWebGLFingerprint() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) { return ''; }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  }

  function getDeviceDetails() {
    return [
      navigator.platform,
      navigator.hardwareConcurrency,
      window.screen.height,
      window.screen.width,
      window.screen.colorDepth
    ].join('|');
  }

  // Combine all components into one string
  const components = [
    navigator.userAgent,
    navigator.language,
    new Date().getTimezoneOffset().toString(),
    getCanvasFingerprint(),
    getWebGLFingerprint(),
    getDeviceDetails()
  ].join('|');

  // Convert the combined string to a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(components);

  // Use the SubtleCrypto API to hash the data
  return crypto.subtle.digest('SHA-256', data)
    .then(hashBuffer => {
      // Convert the hash to Base64 to ensure it's a string suitable for transmission
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return btoa(hashHex);
    })
    .catch(e => console.error('Hashing failed:', e));
}


export default function SignIn() {

  const [username, setUsername] = React.useState("");
  const auth = useAuth(); // Usar el hook useAuth para acceder al contexto de autenticación
  const [verificationCode, setVerificationCode] = React.useState("");
  const [huella,setHuella] = React.useState()

  useEffect(() => {
    async function generateFingerprint() {
      const fingerprint = await generarHuellaDigital();
      setHuella(fingerprint);
    }

    generateFingerprint();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setUsername(data.get('usuario')); // Guarda el username en el estado
    auth.login(data.get('usuario'), data.get('contrasena'), huella);
  };

  const handleVerificationSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    auth.login(username, null, huella, verificationCode);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sistema de Stock Integral
          </Typography>
          {auth.loginError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {auth.loginError}
            </Typography>
          )}
          {!auth.showVerification && (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="contrasena"
                label="Contraseña"
                type="password"
                id="contrasena"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Ingresar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    ¿Olvidaste la contraseña?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
          {auth.showVerification && (
            <Box component="form" onSubmit={handleVerificationSubmit} noValidate sx={{ mt: 1 }}>
              <Typography sx={{ mt: 2, mb: 2 }}>
                Por favor, introduce el código de verificación enviado a tu email.
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="verificationCode"
                label="Código de Verificación"
                name="verificationCode"
                autoComplete="verification-code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verificar
              </Button>
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
