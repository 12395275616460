import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import Box from "@mui/material/Box";

function TablaProductos({ productos, setSelectedRow, selectedRow  }) {
    const [filtro, setFiltro] = useState('sku');
    const [textoFiltro, setTextoFiltro] = useState('');

    const handleFiltroChange = (event) => {
        setFiltro(event.target.value);
    };

    const handleTextoFiltroChange = (event) => {
        setTextoFiltro(event.target.value);
    };

    const productosFiltrados = productos.filter(producto => {
        return producto[filtro] ? producto[filtro].toString().toLowerCase().includes(textoFiltro.toLowerCase()) : false;
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
             <Box display="flex" flexDirection="row" width="100%" pt={5} pb={3}>
                <FormControl sx={{ width: '30%' }}>
                    <InputLabel id="filtro-label">Filtrar por</InputLabel>
                    <Select
                        labelId="filtro-label"
                        value={filtro}
                        label="Filtrar por"
                        onChange={handleFiltroChange}
                        sx={{ mb: 2}}
                    >
                        <MenuItem value="sku">SKU</MenuItem>
                        <MenuItem value="descripcion">Descripción</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    sx={{ width: '70%', ml: 2 }}
                    label={`Buscar por ${filtro}`}
                    value={textoFiltro}
                    onChange={handleTextoFiltroChange}
                    variant="outlined"
                />
            </Box>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell>Descripción</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productosFiltrados.map((producto) => (
                    <TableRow
                        key={producto.id}
                        onClick={() => setSelectedRow(producto)}
                        sx={{
                            backgroundColor: selectedRow?.id === producto.id ? 'rgba(173, 216, 230, 0.5)' : 'inherit',
                            cursor: 'pointer'
                        }}
                    >
                        <TableCell>{producto.sku}</TableCell>
                        <TableCell>{producto.descripcion}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    );
}

export default TablaProductos;
