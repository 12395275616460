import Swal from "sweetalert2";
// Indica si la conexión debe ser segura o no
const secure = true;

// Define el protocolo HTTP/HTTPS basado en la variable `secure`
const protocol = `http${secure ? 's' : ''}`;

// Define el protocolo WebSocket basado en la variable `secure`
const wsProtocol = `ws${secure ? 's' : ''}`;

// Host donde se encuentra el servidor
const HOST = `boxitapi.intelisysdev.com`;

// Construir las URLs base para HTTP y WebSocket
const BASE_HOST = `${protocol}://${HOST}/api/`;
const WS_HOST = `${wsProtocol}://${HOST}/`;

// URLs de los distintos servicios
const API_ML = `${BASE_HOST}api-ml/`;
const API_CORE = `${BASE_HOST}core/`;
export const WS_EMBALAJE = `${WS_HOST}ws/embalaje/?token=<token>`
export const WS_SSH = `${WS_HOST}ssh/?token=<token>`;
// Cabeceras comunes para todas las solicitudes
const base_header = { 'Content-Type': 'application/json' };

async function handleApiResponse(response) {
    if (response.status === 401) {
        await Swal.fire({
            title: 'Sesión expirada',
            text: 'Tu sesión ha expirado. Por favor, sal e  inicia sesión nuevamente.',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
        return null;
    }

    const data = await response.json();
    if (response.ok) {
        return { status: true, data };
    } else {
        return { status: false, mensaje: data.message || response.statusText, data };
    }
}

function loginF(username, password, huella_digital, verification_code = null) {
    const url = `${API_ML}login/`;
    const data = {
        username,
        password,
        huella_digital,
        verification_code
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        // Debemos capturar el estado de 'response' antes de convertirlo a JSON
        const isResponseOk = response.ok; // Capturamos si la respuesta fue exitosa
        const status = response.status; // Capturamos el estado HTTP
        const statusText = response.statusText; // Capturamos el texto del estado
        return response.json().then(data => {
            if (isResponseOk) {
                return {
                    mensaje: "Login exitoso",
                    data: data
                };
            } else {
                return {
                    mensaje: `Error ${status}: ${data.message || statusText}`,
                    data: data
                };
            }
        });
    })
    .catch(error => {
        if (error.name === "TypeError" && error.message === "Failed to fetch") {
            return {
                mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
                data: null
            };
        } else {
            return {
                mensaje: "Error desconocido durante el login",
                data: null
            };
        }
    });
}




function refresh(refreshToken) {
    const url = `${API_ML}login/refresh/`;
    const data = {
        refresh: refreshToken
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
          console.log('Token refreshed:', data);
          return data;
      })
      .catch(error => {
          console.error('Error refreshing token:', error);
      });
}
export async function obtenerPermisosUsuario(token) {
    const url = `${API_ML}login/permissions/`; // Asegúrate de que la URL es correcta

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Autorización con token
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json().then(data => ({
                mensaje: "Permisos obtenidos exitosamente",
                data: data
            }));
        } else {
            return response.json().then(data => {
                return { mensaje: `Error ${response.status}: ${response.statusText}`, data: data };
            });
        }
    })
    .catch(error => {
        return {
            mensaje: "Error al obtener permisos: " + error.message,
            data: null
        };
    });
}


export async function obtener_carritos_de_cuenta_especifica(id_cuenta, token) {
    const url = `${API_CORE}carritos/listar_carritos_de_la_cuenta/${id_cuenta}/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching carritos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function revertir_estados_carritos(token, lista_id_carrito) {
    const url = `${API_CORE}carritos/revertir_estado/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ carrito_ids: lista_id_carrito })  // Enviar IDs de carritos en el cuerpo
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error al revertir los estados de los carritos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function facturarCarritos(token, lista_id_carrito) {
    const url = `${API_CORE}carritos/facturar_carritos/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ carrito_ids: lista_id_carrito })  // Enviar IDs de carritos en el cuerpo
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error en la facturación de carritos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}
export async function asignarCarritos(token, listaIdCarrito) {
    const url = `${API_CORE}carritos/asignar_carritos/`;  // Cambiar el endpoint para asignación

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Asegurar que el token de autenticación es enviado
            },
            body: JSON.stringify({ carrito_ids: listaIdCarrito })  // Enviar IDs de carritos en el cuerpo
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error en la asignación de carritos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function obtenerMisCarritosAsignados(token) {
    const url = `${API_CORE}carritos/mis_carritos_asignados/`;  // Endpoint para obtener carritos asignados

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`  // Asegurar que el token de autenticación es enviado
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error al obtener los carritos asignados:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function cargarProductosPorMarca(marcaId, accessToken) {
    const baseUrl = `${API_CORE}productos/`;

    if (marcaId === 'null'){
        marcaId = null
    }
    let url = `${baseUrl}?marca_id=${marcaId}`;

    if (marcaId === null) {
        url = `${baseUrl}?marca_id=null`;  // Envía 'null' como un string
    }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Could not fetch products:", error);
        return null;
    }
}



export async function obtener_marcas_productos(token) {
    const url = `${API_CORE}productos/marcas/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching marcas:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarProductoVenta(token, payload) {
    const url = `${API_CORE}ventas/agregar_producto_venta/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando producto a la venta:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function quitarProductoVenta(token, payload) {
    const url = `${API_CORE}ventas/quitar_producto_venta/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error quitando producto de la venta:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function listar_cuentas_empresa(token) {
    const url = `${API_ML}cuentas/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching cuentas:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarPuestos(token) {
    const url = `${API_CORE}admin_panel/listar_puestos/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching puestos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarImpresoras(token) {
    const url = `${API_CORE}admin_panel/listar_impresoras/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching impresoras:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarUsuarios(token) {
    const url = `${API_CORE}admin_panel/listar_usuarios/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching usuarios:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarUsuario(token, userData) {
    const url = `${API_CORE}admin_panel/agregar_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando el usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarServidoresImpresion(token) {
    const url = `${API_CORE}admin_panel/listar_servidores_impresion/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching servidores de impresión:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function modificarImpresora(token, impresoraData) {
    const url = `${API_CORE}admin_panel/modificar_impresora/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(impresoraData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error modificando la impresora:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarImpresora(token, impresoraData) {
    const url = `${API_CORE}admin_panel/agregar_impresora/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(impresoraData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando la impresora:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function modificarPuesto(token, puestoData) {
    const url = `${API_CORE}admin_panel/modificar_puesto/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(puestoData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error modificando el puesto:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function cambiarEstadoActivoDeUsuario(token, userId) {
    const url = `${API_CORE}admin_panel/cambiar_estado_activo_de_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: userId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error cambiando el estado activo del usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarServidorCups(token, servidorData) {
    const url = `${API_CORE}admin_panel/agregar_servidor_cups/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(servidorData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando el servidor CUPS:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarPuesto(token, puestoData) {
    const url = `${API_CORE}admin_panel/agregar_puesto/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(puestoData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando el puesto:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function eliminarServidorCups(token, servidorId) {
    const url = `${API_CORE}admin_panel/eliminar_servidor_cups/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: servidorId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error eliminando el servidor CUPS:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarEmpresasDisponibles(token) {
    const url = `${API_CORE}admin_panel/listar_empresas_disponibles/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching empresas:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function eliminarImpresora(token, impresoraId) {
    const url = `${API_CORE}admin_panel/eliminar_impresora/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: impresoraId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error eliminando la impresora:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function eliminarUsuario(token, usuarioId) {
    const url = `${API_CORE}admin_panel/eliminar_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: usuarioId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error eliminando el usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function eliminarPuesto(token, puestoId) {
    const url = `${API_CORE}admin_panel/eliminar_puesto/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ puesto_id: puestoId })
        });

        await handleApiResponse(response);

        if (response.ok) {
            return {
                status: true,
                mensaje: "Puesto eliminado correctamente"
            };
        } else {
            const errorData = await response.json();
            return {
                status: false,
                mensaje: `Error ${response.status}: ${response.statusText}`,
                data: errorData
            };
        }
    } catch (error) {
        console.error('Error eliminando el puesto:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarGrupos(token) {
    const url = `${API_CORE}admin_panel/listar_grupos/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching grupos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function agregarUsuarioGrupo(token, userId, groupId) {
    const url = `${API_CORE}admin_panel/agregar_usuario_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId, group_id: groupId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando grupo al usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function quitarUsuarioGrupo(token, userId, groupId) {
    const url = `${API_CORE}admin_panel/quitar_usuario_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId, group_id: groupId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error quitando grupo del usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}
export async function actualizarUsuario(accessToken, userId, email, password) {
    const url = `${API_CORE}admin_panel/actualizar_usuario/`;
    const payload = { id: userId, email, password };

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error updating user:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function quitar_restriccion_ean(token, carrito_id) {
    const url = `${API_CORE}carritos/${carrito_id}/quitar_restriccion_ean/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error al quitar la restricción EAN:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function listarPermisos(token) {
    const url = `${API_CORE}admin_panel/listar_permisos/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching permisos:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function crearGrupo(token, data) {
    const url = `${API_CORE}admin_panel/crear_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error creating grupo:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function eliminarGrupo(token, grupoId) {
    const url = `${API_CORE}admin_panel/eliminar_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ id: grupoId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error deleting grupo:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function obtenerGeneralPorUsuarioDeFechaEspecifica(token, fecha) {
    const url = `${API_CORE}estadisticas_usuarios/general-por-usuario-de-fecha-especifica/?fecha=${fecha}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching general por usuario de fecha específica:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function obtenerGeneralDiarioPorUsuario(token) {
    const url = `${API_CORE}estadisticas_usuarios/general-diario-por-usuario/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching general diario por usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function obtenerTiemposPromedioPorUsuario(token) {
    const url = `${API_CORE}estadisticas_usuarios/tiempos-promedio-por-usuario/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching tiempos promedio por usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}






export { loginF, refresh };
